import axios from '@/utils/request'

// 交易通知-批量查询
export function operationQueryBatch(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/trade`,
    method: 'get',
    params
  })
}

// 交易通知-订单查询
export function operationQueryOrder(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/trade/search`,
    method: 'get',
    params
  })
}

// 交易通知-订单详情
export function operationDetail(orderId) {
  return axios({
    url: `/manager/operation/notices/trade/${orderId}`,
    method: 'get',
  })
}

// 退款通知-批量查询
export function refundQueryBatch(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/refund`,
    method: 'get',
    params
  })
}

// 退款通知-订单查询
export function refundQueryOrder(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/refund/search`,
    method: 'get',
    params
  })
}

// 退款通知-订单详情
export function refundDetail(refundId) {
  return axios({
    url: `/manager/operation/notices/refund/${refundId}`,
    method: 'get',
  })
}

// 代付通知-批量查询 
export function agnpayQueryBatch(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/agnpay`,
    method: 'get',
    params
  })
}

// 代付通知-订单查询
export function agnpayQueryOrder(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/agnpay/search`,
    method: 'get',
    params
  })
}

// 代付通知-订单详情
export function agnpayDetail(payId) {
  return axios({
    url: `/manager/operation/notices/agnpay/${payId}`,
    method: 'get',
  })
}


// 分账通知-批量查询
export function allocfundQueryBatch(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/allocfund`,
    method: 'get',
    params
  })
}

// 分账通知-订单查询
export function allocfundQueryOrder(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/allocfund/search`,
    method: 'get',
    params
  })
}

// 分账通知-订单详情
export function allocfundDetail(allocOrderId) {
  return axios({
    url: `/manager/operation/notices/allocfund/${allocOrderId}`,
    method: 'get',
  })
}

// 担保通知-批量查询
export function guaranteeQueryBatch(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/guarantee`,
    method: 'get',
    params
  })
}

// 担保通知-订单查询
export function guaranteeQueryOrder(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/guarantee/search`,
    method: 'get',
    params
  })
}

// 担保通知-订单详情
export function guaranteeDetail(guaranteeOrderId) {
  return axios({
    url: `/manager/operation/notices/guarantee/${guaranteeOrderId}`,
    method: 'get',
  })
}


// 交易通知-同步
export function tradePost(data) {
  return axios({
    url: `/manager/operation/notices/trade/notify`,
    method: 'post',
    data
  })
}

//  退款通知-同步
export function refundPost(data) {
  return axios({
    url: `/manager/operation/notices/refund/notify`,
    method: 'post',
    data
  })
}
// 代付通知-同步
export function agnpayPost(data) {
  return axios({
    url: `/manager/operation/notices/agnpay/notify`,
    method: 'post',
    data
  })
}
// 分账通知-同步
export function allocfundPost(data) {
  return axios({
    url: `/manager/operation/notices/allocfund/notify`,
    method: 'post',
    data
  })
}
// 担保通知-同步
export function guaranteePost(data) {
  return axios({
    url: `/manager/operation/notices/guarantee/notify`,
    method: 'post',
    data
  })
}
// 结算通知-批量查询
export function refundJSQueryBatch(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/settle`,
    method: 'get',
    params
  })
}

// 结算通知-订单查询
export function refundJSQueryOrder(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/settle/search`,
    method: 'get',
    params
  })
}

// 结算通知-订单详情
export function refundJSDetail(settleOrderId) {
  return axios({
    url: `/manager/operation/notices/settle/${settleOrderId}`,
    method: 'get',
  })
}

// 结算通知-同步
export function refundJSPost(data) {
  return axios({
    url: `/manager/operation/notices/settle/notify`,
    method: 'post',
    data
  })
}
// 转账通知-批量查询
export function transferZZQueryBatch(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/transfer`,
    method: 'get',
    params
  })
}

// 转账通知-订单查询
export function transferZZQueryOrder(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/transfer/search`,
    method: 'get',
    params
  })
}

// 转账通知-订单详情
export function transferZZDetail(transferId) {
  return axios({
    url: `/manager/operation/notices/transfer/${transferId}`,
    method: 'get',
  })
}

// 转账通知-同步
export function transferZZPost(data) {
  return axios({
    url: `/manager/operation/notices/transfer/notify`,
    method: 'post',
    data
  })
}

// 转账通知 - 批量查询 - 批量转账 - 列表
export function transferZZQueryorderBatch(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/transfer/batch`,
    method: 'get',
    params
  })
}

// 转账通知 - 订单查询 - 批量转账 - 列表
export function transferZZQueryOrderSearch(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/transfer/batch/search`,
    method: 'get',
    params
  })
}

// 转账通知 - 批量查询 - 批量转账 - 同步
export function transferZZPostBatch(data) {
  return axios({
    url: `/manager/operation/notices/transfer/batch/notify`,
    method: 'post',
    data
  })
}

// 转账通知-批量转账-订单详情
export function transferZZBatchDetail(transferId) {
  return axios({
    url: `/manager/operation/notices/transfer/batch/${transferId}`,
    method: 'get',
  })
}

// 分账代付通知-批量查询
export function allocpayfundQueryBatch(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/allocpay`,
    method: 'get',
    params
  })
}

// 分账代付通知-订单查询
export function allocpayfundQueryOrder(params) {
  // params.loading = true
  return axios({
    url: `/manager/operation/notices/allocpay/search`,
    method: 'get',
    params
  })
}

// 分账代付通知-订单详情
export function allocpayfundDetail(payId) {
  return axios({
    url: `/manager/operation/notices/allocpay/${payId}`,
    method: 'get',
  })
}
// 分账代付通知-同步
export function allocpayPost(data) {
  return axios({
    url: `/manager/operation/notices/allocpay/notify`,
    method: 'post',
    data
  })
}